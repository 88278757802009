<template>
  <transition v-if="!loading" name="fade">
    <div id="wrapper">
        <sidebar :page="'Manage Suppliers'" :sidebar_data=sidebar_data></sidebar>
        <div class="body">
            <div class="backdrop" v-on:click="close()"></div>
              
            <admin-header :title="'Manage Suppliers'" :tagline="'All your controls, in one place.'" v-model:all_screens=all_screens v-model:screen=screen v-model:url=url  :user=user style="margin-bottom: 3rem;"></admin-header>

            <div class="container mb-4">
              <div class="content-wrapper">
                <div class="content mx-auto">

                  <!--  List 1 -->
                  <auto-list v-if="url != null" v-model:all_screens=all_screens v-model:screen="screen" :title=title v-model:url="url" v-model:list_data="list_data"></auto-list>

                </div>                                          
              </div>
              </div>
            
            <main-footer></main-footer>
        </div>

        <add-supplier :user_id=user_id></add-supplier>
    </div>
  </transition>
</template>

<script>
import MainFooter from '../../components/Footer'
import Sidebar from '../../components/AutoSidebar'
import AdminHeader from '../../components/Header'
import AutoList from '../../components/AutoList'
import AddSupplier from '../../components/Modals/AddSupplier'
import SidebarData from '../../assets/json/Admin/SidebarData'

export default {
  name: 'AdminSuppliers',
  components: {
    AdminHeader,
    MainFooter,
    Sidebar,
    AutoList,
    AddSupplier
  },
  data() {
    return {
      user_id: 1,
      url: 'api/suppliers',
      title: {status: true, title: 'Your Data', actions: [
        {name: 'Add Suppliers', type: 'modal', action_url: 'add-supplier'}
      ]},
      all_screens: [
        {title: 'Our Suppliers', screen: 1, url: 'api/active-users', actions: [
          {name: 'Edit', type: 'modal', action_url: 'edit-supplier'},
          {name: 'Remove', type: 'api', action_url: {type: 'delete', url:'api/suppliers/', message: {success: 'Success!', error: 'Error!'}}}
        ]},                
      ],   
      sidebar_data: SidebarData,
      screen: 1,
      list_data: []          
    }
  },
  watch: {
    user: function() {
      this.ifNotAdmin()
    }
  },    
  computed: {
    user () {
      return this.$store.state.user
    },
    loading () {
      return this.$store.state.loading
    }       
  },
  mounted() {
    this.$store.dispatch("getUser")
  },
  methods: {
    ifNotAdmin() {
      if( !this.user.admin) {
        this.$router.push({name: 'Home'});
      }
    }
  }  

}
</script>
