<template>
    <!-- Modal -->
    <div class="modal fade" id="add-supplier" tabindex="-1" role="dialog" aria-labelledby="formsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min">Add Supplier</h5>
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                        <form action="#" enctype="multipart/form-data" @submit.prevent="handleSubmit()">
                            <div class="col-md-12 col-sm-12">
                                <status v-model:status_data=status_data></status>

                                <div class="row">
                                    <div class="col-md-6 col-sm-12 mt-4" id="name">
                                        <label for="business.name" class="fw-bold mb-2">Enter your Business Name</label>  
                                        <div class="input-group">
                                            <span class="input-group-text" >
                                                <font-awesome-icon id="lock" :icon="['fa', 'users']" />
                                            </span>

                                            <input id="business.name" type="address" class="form-control" v-model="data.supplier.name" placeholder="Enter your business name." required>
                                        </div>

                                        <small v-if="errors.name" class="text-danger">
                                            <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                            {{ errors.name }}
                                        </small>
                                    </div>
                                    
                                    <div class="col-md-6 col-sm-12 mt-4" id="type">
                                        <label for="business.type" class="fw-bold mb-2">Enter your Supplier Type</label>  
                                        <div class="input-group">
                                            <span class="input-group-text" >
                                                <font-awesome-icon id="lock" :icon="['fa', 'truck-loading']" />
                                            </span>

                                            <select id="business.type" class="form-select" v-model="data.supplier.type" required>
                                                <option value="Pharmacy">Pharmacy</option>
                                                <option value="Manuafcturer">Manufacturer</option>
                                            </select>
                                        </div>
                                        <small v-if="errors.type" class="text-danger">
                                            <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                            {{ errors.type }}
                                        </small>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-sm-12 mt-4">
                                        <label for="business.corporation_type" class="fw-bold mb-2">Enter your Business Type</label>  
                                        <div class="input-group">
                                            <span class="input-group-text" >
                                                <font-awesome-icon id="lock" :icon="['fa', 'building']" />
                                            </span>

                                            <select id="business.corporation_type" class="form-select" v-model="data.supplier.corporation_type" required>
                                                <option value="Sole Proprietorship">Sole Proprietorship</option>
                                                <option value="General Partnership">General Partnership</option>
                                                <option value="Limited Partnership">Limited Partnership</option>
                                                <option value="Limited Partnership">Limited Partnership</option>
                                                <option value="Corporation">Corporation</option>
                                            </select>
                                        </div>

                                        <small>What business type are you registered as? e.g. Partnership or Corporation</small>

                                        <small v-if="errors.corporation_type" class="text-danger">
                                            <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                            {{ errors.corporation_type }}
                                        </small>
                                    </div>
                                    
                                    <div class="col-md-6 col-sm-12 mt-4" id="business-type">
                                        <label for="business.business_certificate" class="fw-bold mb-2">Upload your Business Certificate</label>  

                                        <div class="input-group">
                                            <input type="file" id="business.business_certificate" class="form-control" @change="imageEvent($event)">
                                        </div>

                                        <small>Upload a DOCX or a PDF</small>
                                        
                                        <small v-if="errors.business_certificate" class="text-danger">
                                            <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                            {{ errors.business_certificate }}
                                        </small>
                                    </div>                           
                                </div>                    

                                <div class="row">
                                    <div class="col-md-6 col-sm-12 mt-4" id="address">
                                        <label for="business.address" class="fw-bold mb-2">Enter your Business Address</label>  
                                        <div class="input-group">
                                            <span class="input-group-text" >
                                                <font-awesome-icon id="lock" :icon="['fa', 'location-arrow']" />
                                            </span>

                                            <input id="business.address" type="text" class="form-control" v-model="data.supplier.address" placeholder="Enter your address." required>
                                        </div>

                                        <small v-if="errors.address" class="text-danger">
                                            <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                            {{ errors.address }}
                                        </small>
                                    </div>

                                    <div class="col-md-6 col-sm-12 mt-4" id="address">
                                        <label for="business.number" class="fw-bold mb-2">Enter your Business Registration Number</label>  

                                        <div class="input-group">
                                            <span class="input-group-text" >
                                                <font-awesome-icon id="lock" :icon="['fa', 'id-card']" />
                                            </span>

                                            <input id="business.number" type="number" class="form-control" v-model="data.supplier.business_number" placeholder="Enter your Business Registration Number." required>
                                        </div>

                                        <small v-if="errors.business_number" class="text-danger">
                                            <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                            {{ errors.business_number }}
                                        </small>
                                    </div>
                                </div> 

                                <div class="row">
                                    <div class="col-md-6 col-sm-12 mt-4" id="address">
                                        <label for="business.email" class="fw-bold mb-2">Enter your Business Email</label> 

                                        <div class="input-group">
                                            <span class="input-group-text" >
                                                <font-awesome-icon id="lock" :icon="['fa', 'envelope']" />
                                            </span>

                                            <input id="business.email" type="email" class="form-control" v-model="data.supplier.email" placeholder="Enter your business email." required>
                                        </div>
                                        
                                        <small v-if="errors.email" class="text-danger">
                                            <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                            {{ errors.email }}
                                        </small>
                                    </div>

                                    <div class="col-md-6 col-sm-12 mt-4" id="address">
                                        <label for="business.phone_number" class="fw-bold mb-2">Enter your Business Phone Number</label>  
                                        <div class="input-group">
                                            <span class="input-group-text" >
                                                <font-awesome-icon id="lock" :icon="['fa', 'phone-alt']" />
                                            </span>

                                            <input id="business.phone_number" type="number" class="form-control" v-model="data.supplier.phone_number" placeholder="Enter your Business Phone Number." required>
                                        </div>

                                        <small v-if="errors.phone_number" class="text-danger">
                                            <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                            {{ errors.phone_number }}
                                        </small>
                                    </div>
                                    
                                </div> 
                                
                                <div class="row" id="address">
                                    <div class="col-md-6 col-sm-12 mt-4"> 
                                        <label for="business.description" class="fw-bold mb-3">Enter a brief description of your business.</label>  
                                        <div class="input-group">
                                            <span class="input-group-text" >
                                                <font-awesome-icon id="lock" :icon="['fa', 'users']" />
                                            </span>

                                            <textarea rows="4" id="business.description" type="text" class="form-control" v-model="data.supplier.description" placeholder="Enter your address."  required style="border-left: 1px solid #eee;"></textarea>
                                        </div>

                                        <small v-if="errors.description" class="text-danger">
                                            <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                            {{ errors.description }}
                                        </small>
                                    </div>
                                </div>

                                <button class="btn bg-orange text-uppercase text-white mt-4 w-100">
                                    <span class="m-0" style="letter-spacing: 1px">Finish</span> <font-awesome-icon class="ms-3" :icon="['fas', 'check']"></font-awesome-icon>
                                </button>
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';

    .form-select {
        border: 0;
        border-bottom: 1px solid #eee;
    }
    
    .modal-dialog {
        max-width: 1000px;
    }
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'AddSupplier',
        props: {
            user_id: {
                required: true,
                type: Number
            }
        },
        components: {
            Status
        },
        data() {
            return {
                screen: 0,
                data: {
                    supplier: {
                        name: null,
                        description: null,
                        address: null,
                        phone_number: null,
                        email: null,
                        business_number: null,
                        corporation_type: null,
                        type: null,
                        user_id: this.user_id
                    }
                },
                status_data: {
                    "success": false,
                    "success_message": 'Form Submission was successful!',
                    "error": false,
                    "error_message": 'There are some errors.',
                    "loading": false
                },
                errors: [],
                image_url: null
            }
        },
        methods: {
            imageEvent(event) {
                this.image_url = event
            },            
            handleSubmit() {
                this.errors = []

                this.status_data.loading = true
                let formData = new FormData()

                formData.append("name", this.data.supplier.name)
                formData.append('email', this.data.supplier.email)
                formData.append('phone_number', this.data.supplier.phone_number)
                formData.append('business_number', this.data.supplier.business_number)
                formData.append('type', this.data.supplier.type)
                formData.append('corporation_type', this.data.supplier.corporation_type)
                formData.append('address', this.data.supplier.address)
                formData.append('description', this.data.supplier.description)
                formData.append('business_certificate', formData.append('business_certificate', this.image_url.target.files[0]))                
                formData.append('user_id', this.data.supplier.user_id)

                this.axios.post('api/suppliers', formData).then(() => {
                    this.status_data.loading = false
                    this.status_data.error = false
                    this.status_data.success = true
                    this.status_data.success_message = 'Supplier Added!'
                }).catch((errors) => {
                    this.status_data.loading = false
                    this.status_data.error = true
                    this.status_data.success = false
                    this.status_data.error_message = 'Errors found.'
                    
                    if(errors.message) {
                        this.status_data.error_message = 'Errors found. ' + errors.message
                    }

                    if(errors.data) {
                        this.errors = errors.data
                    }
                })
            },
            nextScreen() {
                this.screen++
            },
            previousScreen() {
                if(this.screen > 0) {
                    this.screen--
                }
            },
        }
    }
</script>